@use 'variables';

.component-jobOfferImage {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
    position: relative;

    &.open {
        .component-jobOfferImage-tooltip {
            display: block;
            position: absolute;
            bottom: 95%;
            margin-right: 20px;
        }
    }

    &-title {
        font-size: 1rem;
        line-height: 1.688rem;
        margin-bottom: 20px;
        text-align: center;
        @include variables.bold-font;
    }

    &-image {
        position: relative;
        img {
            border-radius: 10px;
        }
    }

    &-tooltip {
        margin-bottom: 30px;
        text-align: center;
        background-color: #000;
        color: #fff;
        padding: 20px;
        border-radius: 10px;
        position: relative;
        p {
            font-size: 1rem;
            line-height: 1.688rem;
        }

        &::before {
            position: absolute;
            content: '';
            bottom: -12px;
            width: 0;
            height: 0;
            border-left: 12px solid transparent;
            border-right: 12px solid transparent;
            border-top: 12px solid #00A053;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &-tip {
        cursor: pointer;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #00A053;
        position: absolute;
        top: 20px;
        right: 20px;
        background-image: url("../../../../images/exclamation_mark.svg");
        background-size: 4px;
        background-repeat: no-repeat;
        background-position: center;
    }

    @include variables.for-mediumUp-only {
        &-tooltip,
        &-tip {
            display: none;
        }
    }

    @include variables.for-desktop-up {
        &-tip {
            display: block;
        }
    }
}
