@use 'variables';

.component-jobOfferBoxes {
    margin: 0px -15px;

    &__content {
        background-color: #000;
        padding: 60px 15px;
        flex: 0 0 50%;
        color: #fff;

        h2 {
            font-size: 2.2rem;
            line-height: 3.2rem;
            margin-bottom: 15px;
        }

        h3 {
            font-size: 1rem;
            line-height: 1.688rem;
            margin-bottom: 15px;
        }

        p {
            font-size: 1rem;
            line-height: 1.688rem;
            margin-bottom: 40px;
            @include variables.regular-font;
        }

        strong {
            font-size: inherit;
            @include variables.bold-font;
        }

        ul {
            margin: 15px 0px 40px 0px;
        }

        li {
            font-size: 1rem;
            list-style: none;
            line-height: 1.688rem;
            margin-bottom: 0.625rem;
            padding-left: 30px;
            position: relative;
            @include variables.regular-font;
        }

        li:before {
            position: absolute;
            background-color: #fff;
            border-radius: 2px;
            border: 2px solid #fff;
            content: '';
            display: inline-block;
            margin-right: 15px;
            vertical-align: middle;
            left: 0;
            top: 10px;
            padding-left: 17px;
        }

        a {
            text-decoration: underline;
        }
    }

    &__image {
        min-height: 410px;
    }

    &__button {
        display: flex;
        p {
            cursor: pointer;
            font-size: 1rem;
            line-height: 1.25rem;
            padding: 14px 24px;
            margin-bottom: 0px;
            border-radius: 4px;
            background-color: #fff;
            color: #000;
            @include variables.bold-font;
        }
    }

    @include variables.for-mediumUp-only {
        &__image {
            min-height: 510px;
        }
    }

    @include variables.for-tablet-portrait-up {
        display: flex;
        flex-wrap: wrap;

        &__box {
            display: flex;
            flex: 0 0 100%;
            &:nth-child(2) {
                flex-direction: row-reverse;
            }
        }

        &__image {
            flex: 0 0 50%;
            min-height: auto;
        }
    }

    @include variables.for-tablet-landscape-up {
        margin: 0px;

        &__content {
            padding: 80px;

            h2 {
                font-size: 2.5rem;
                line-height: 3.125rem;
            }

            h3 {
                font-size: 1.875rem;
                line-height: 2.5rem;
            }

            p {
            font-size: 1.125rem;
            line-height: 1.875rem;
        }
        }
    }

    @include variables.for-large-desktop-up {
        &__image {
            min-height: 800px;
        }
    }
}
