@use 'variables';

.component-jobOffersCarousel {
    border-top: 1px solid #e2e2e2;

    &-title {
        margin: 80px 0px;
        text-align: center;
        h2 {
            font-size: 2.2rem;
            line-height: 3.2rem;
        }
    }

    &-image {
        padding: 0px 10px;
        img {
            border-radius: 10px;
        }
    }

    .slick-slider {
        .slick-arrow {
            background: #d5d5d5;
            border-radius: 5px;
            display: flex !important;
            height: 80px;
            justify-content: center;
            z-index: 10;
            top: 50%;
            width: 40px;
            transition: all 0.1s;
            &:hover {
                background-color: #000;
                transition: all 0.1s;
            }
            &.slick-prev {
                left: 10px;
            }
            &.slick-next {
                right: 10px;
            }
            &:before {
                content: '';
            }
            img {
                width: 10px;
            }
        }
        .awesome-dots {
            margin: 0;
            padding-top: 40px;
            text-align: center;
            width: 100%;
            li {
                display: inline-block;
                margin: 0 7.5px;
                position: relative;
                cursor: pointer;
                span {
                    background: #e2e2e2;
                    border-radius: 2px;
                    display: block;
                    height: 4px;
                    width: 30px;
                }
                &.slick-active {
                    span {
                        background: #000000;
                    }
                }
            }
        }
    }

    @include variables.for-tablet-landscape-up {
        &-title {
            margin: 110px 0px;
            h2 {
                font-size: 2.5rem;
                line-height: 3.125rem;
            }
        }
    }
}
