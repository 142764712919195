@use 'variables';

.component-jobOffersPage {
  &-title {
    text-align: center;
    margin: 80px auto;
    max-width: 800px;
    h2 {
      font-size: 2.2rem;
      line-height: 3.2rem;
      margin-bottom: 15px;
    }

    p {
      font-size: 1rem;
      line-height: 1.688rem;
    }
  }

  &-container {
    max-width: 1360px;
    margin: 0 auto;
  }

  @include variables.for-tablet-landscape-up {
    &-title {
      margin: 110px auto;
      h2 {
        font-size: 2.5rem;
        line-height: 3.125rem;
      }

      p {
        font-size: 1.125rem;
        line-height: 1.875rem;
      }
    }
  }
}
