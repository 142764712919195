@use 'variables';

.component-jobOfferVideo {
    position: relative;
    margin: -20px -15px 0px -15px;

    @include variables.for-tablet-landscape-up {
        width: 100%;
        height: 0;
        padding-bottom: calc(23 / 48 * 100%);
    }

    &-container {
        @include variables.for-tablet-landscape-up {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }
    }

    &-svgWhite {
        display: none;
        margin-bottom: 60px;
    }

    &-svgBlack {
        width: 100%;
        padding: 0px 30px 30px 30px;
    }

    &-desktop {
        display: none;
        margin-bottom: -3px;
    }

    &-mobile {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: calc(100%*1/1);

        &__video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 30px 15px 80px 15px;
    }

    &-button {
        display: flex;
        p {
            cursor: pointer;
            font-size: 1rem;
            line-height: 1.5rem;
            padding: 14px 24px;
            margin-bottom: 0px;
            border-radius: 4px;
            background-color: #fff;
            border: 1px solid #e2e2e2;
            color: #000;
            text-align: center;
            @include variables.bold-font;
        }
    }

    @include variables.for-tablet-landscape-up {
        margin: 0;

        &-desktop {
            display: block;
        }

        &-mobile {
            display: none;
        }

        &-svgBlack {
            display: none;
        }

        &-svgWhite {
            display: block;
            width: 100%;
        }

        &-content {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            top: 0;
            left: 0px;
            right: 0px;
            bottom: 0;
            padding: 80px 80px 60px 80px;
            margin: 0;
        }

        &-button {
            p {
                background-color: transparent;
                border: 1px solid #fff;
                color: #fff;
            }
        }
    }
}
