@use 'variables';

.component-jobOfferBanner {
  border-top: 1px solid #e2e2e2;
  padding: 80px 0px;
  &-image {
    img {
      border-radius: 10px;
    }
  }

  &-button {
    display: flex;
    p {
      cursor: pointer;
      font-size: 1rem;
      line-height: 1.25rem;
      padding: 14px 24px;
      margin: 0;
      border-radius: 4px;
      background-color: #00A053;
      color: #fff;
      @include variables.bold-font;
    }
  }

  &-modal {
    &__overlay {
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: 99999;
      cursor: pointer;
    }

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      h4 {
        font-size: 1.8rem;
        line-height: 1.688rem;
      }
    }

    &__container {
      padding: 40px;
      background-color: #fff;
      border-radius: 10px;
    }

    &__content {
      position: relative;
      overflow: hidden;
      padding-top: 56.25%;
      height: 65vh;
      width: 75vw;
    }

    &__iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }

    &__close {
      width: 40px;
      height: 40px;
      background: #000;
      position: relative;
      margin-left: auto;
      border-radius: 4px;
      cursor: pointer;
      &:before,
      &:after {
        position: absolute;
        left: 48%;
        top: 26%;
        content: ' ';
        height: 20px;
        width: 2px;
        background-color: #fff;
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }

  &-content {
    margin-top: 30px;

    h3 {
      font-size: 1.125rem;
      line-height: 1.6875rem;
      margin: 15px 0px;
    }

    p {
      font-size: 1rem;
      line-height: 1.688rem;
      margin: 15px 0px;
    }
  }

  @include variables.for-tablet-landscape-up {
    padding: 110px 0px;
    &-container {
      display: flex;
      max-width: 1360px;
      margin: 0 auto;
    }

    &-image {
      flex: 0 0 50%;
    }

    &-content {
      flex: 0 0 50%;
      padding-left: 50px;
      margin-top: 0px;

      h3 {
        font-size: 1.875rem;
        line-height: 2.5rem;
      }
    }

    &-modal {
      &__content {
        max-width: 760px;
      }

      &__header {
        h4 {
          font-size: 2.6rem;
          line-height: 3.6rem;
        }
      }
    }
  }
}
