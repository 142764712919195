@use 'variables';

.component-jobOffers {
  border-top: 1px solid #e2e2e2;
  padding: 80px 0px 40px 0px;
  &-header {
    margin-bottom: 80px;
    h2 {
      font-size: 2.2rem;
      line-height: 3.2rem;
    }
  }

  &-list {
    margin: 0px -10px;
  }

  &-nooffers {
    text-align: center;
    padding-bottom: 40px;
  }

  &-container {
    max-width: 1360px;
    margin: 0 auto;
  }

  &-dropdown {
    margin: 30px 0px 0px 0px;
    p {
      @include variables.bold-font;
    }

    &__class {
      min-width: 200px;
      margin-top: 10px;
      box-shadow: none !important;
      &__value-container {
        height: 50px;
        padding-left: 15px !important;
        cursor: pointer;
      }

      &__option {
        display: flex !important;
        align-items: center !important;
        padding-left: 15px !important;
        height: 50px;
        cursor: pointer !important;
        @include variables.bold-font;
        &:hover {
          background-color: #00A053;
          color: #fff;
        }
        &--is-focused,
        &--is-selected {
          background-color: #00A053 !important;
          color: #fff !important;
        }
      }

      &__control {
        border: 1px solid #e2e2e2 !important;
        box-shadow: none !important;
        &--menu-is-open {
          box-shadow: none !important;
        }
      }

      &__placeholder {
        color: #000 !important;
        @include variables.bold-font;
      }

      &__single-value {
        color: #000 !important;
        @include variables.bold-font;
      }
    }
  }

  @include variables.for-mediumUp-only {
    &-list {
      display: flex;
      flex-wrap: wrap;
    }
  }

  @include variables.for-tablet-landscape-up {
    padding: 110px 0px 50px 0px;
    &-header {
      display: flex;
      align-items: flex-end;
      margin: 0px 0px 110px 0px;
      h2 {
        font-size: 2.5rem;
        line-height: 3.125rem;
      }
    }

    &-list {
      margin: 0px -20px;
    }

    &-dropdowns {
      display: flex;
      margin-left: auto;
    }

    &-dropdown {
      margin: 0px 0px 0px 30px;
    }

    &-nooffers {
      padding-bottom: 60px;
    }
  }
}
