@use 'variables';

.component-jobOffer {
    padding: 0px 10px 40px 10px;
    width: 100%;

    &-container {
        padding: 15px;
        border: 1px solid #e2e2e2;
        border-radius: 10px;
    }

    &-image {
        img {
            border-radius: 10px;
        }
    }

    &-content {
        margin-top: 30px;
    }

    &-location {
        margin-bottom: 10px;
        p {
            font-size: 0.875rem;
            line-height: 2.2rem;
            @include variables.bold-font;
            span {
                @include variables.regular-font;
            }
        }
    }

    &-title {
        margin-bottom: 30px;
        h4 {
            font-size: 1.6rem;
            line-height: 1.5rem;
        }
    }

    &-date {
        margin-bottom: 30px;
        p {
            font-size: 0.875rem;
            line-height: 2.2rem;
            color: #00A053;
            @include variables.bold-font;
        }

        span {
            font-size: 1rem;
            line-height: 1.688rem;
        }
    }

    &-button {
        width: 100%;
        p {
            font-size: 1rem;
            line-height: 1.688rem;
            padding: 14px 16px;
            border: 1px solid #e2e2e2;
            border-radius: 4px;
            text-align: center;
            @include variables.bold-font;
        }

        @include variables.for-tablet-landscape-up {
            p {
                cursor: pointer;
                &:hover {
                    background-color: #00A053;
                    border: 1px solid #00A053;
                    color: #fff;
                }
            }
        }
    }

    @include variables.for-tablet-portrait-up {
        flex: 0 0 50%;
    }

    @include variables.for-desktop-up {
        padding: 0px 20px 60px 20px;

        &-container {
            display: flex;
            padding: 30px;
        }

        &-content {
            display: flex;
            flex-direction: column;
            margin: 0px 0px 0px 30px;
            flex: 1;
        }

        &-title {
            flex: 1;
            h4 {
                font-size: 1.8rem;
                line-height: 1.75rem;
            }
        }

        &-image {
            max-width: 250px;
        }

        &-date {
            margin-bottom: 15px;
        }
    }

    @include variables.for-large-desktop-up {
        &-image {
            max-width: 315px;
        }

        &-title {
            h4 {
                font-size: 2.5rem;
                line-height: 3.5rem;
            }
        }
    }
}
