@use 'variables';

.component-jobOfferImages {
    max-width: 1360px;
    margin: 0 auto;
    @include variables.for-mediumUp-only {
        &-list {
            display: flex;
            flex-wrap: wrap;
            margin: 0px -20px;
        }

        .component-jobOfferImage {
            flex: 0 0 50%;
            padding: 20px;
            margin: 0px 0px 80px 0px;
        }
    }

    @include variables.for-tablet-portrait-up {
        .component-jobOfferImage {
            flex: 0 0 33.33%;
            justify-content: flex-end;
        }
    }

    @include variables.for-tablet-landscape-up {
        .component-jobOfferImage {
            flex: 0 0 25%;
            margin-bottom: 60px;
        }

        &-title {
            margin-bottom: 26px;
        }

        &-list {
            margin: 0px -20px 30px -20px;
        }
    }
}
